<template>
  <div>
    Inspection
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
export default defineComponent({
  name: 'Inspection',
  components: {},
  props: {},
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
<style lang='less' scoped>

</style>
